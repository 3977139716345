<template>
    <div class="login">
        <div class="flex1 login1">

        </div>
        <div class="flex2 login2">
            <el-form :model="form" ref="submitForm" label-width="auto" :rules="rules">
            <div class="login2a">
                <h1 class="tc col24 font26 padb30">账户密码登录</h1>
                <el-form-item prop="mobile" >
                <el-input placeholder="输入手机号/邮箱号" size="large" v-model="form.mobile" class="bora4" />
                </el-form-item>
                <p class="h10"></p>
                <el-form-item prop="password" >
                <el-input @change="tologin" v-model="form.password" placeholder="请输入密码" size="large" show-password />
                </el-form-item>
                <p class="mart20 marb10 col9 font12 lh28">登录即表明您同意<router-link to="userAgreement" class="colzhuti">《海量技术支持隐私声明》</router-link>及<router-link to="privacyPolicy" class="colzhuti">《用户协议》</router-link></p>
                <el-button :loading="loading" type="primary" size="large" @click="tologin"  class="w100">登 录</el-button>
                <div class="flex flexsa w100 col4c font14 padt30 padb10">
                    <router-link class="col4c" to="/register" >新用户注册</router-link>
                    <router-link class="col4c" to="/password" >忘记密码</router-link>
                </div>
                <!-- <div class="logintxt">
                    每个公司客户都有唯一的客户服务号（CSI），只有注册时关联了CSI号且CSI在有效期内的账户才能提交技术工单
                </div> -->
            </div>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            loading: false,
            form: {mobile: '',password: ''},
            rules: {
                mobile: [
                    {  required: true, message: '请输入手机号或邮箱号', trigger: 'blur' },
                    {
                        validator(rule, value, callback) {
                            const _m = /^1(3|4|5|6|7|8|9)\d{9}$/.test(value)
                            const _e = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$").test(value)
                            if (_m || _e) {
                                return callback()
                            }
                            return callback(new Error('请输入正确的手机号或邮箱号'))
                        }, trigger: 'blur'
                    }
                ],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
            },
        }
    },
    methods:{
        async tologin(val){
            try {
                await this.$refs['submitForm'].validate()
                this.loading = true
                const res = await this.$api.login.userlogin(this.form)
                if(res){
                    console.log('获取用户详情',res)
                    this.$store.commit('setToken', res.token);
                    this.$store.commit('setIsbind', res.isBind);
                    this.$store.commit('setUserinfo', JSON.stringify(res) );
                    this.$router.push({
                        path: this.$route.query.redirect??'/controlBoard'
                    })
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.loading = false
            }
        }
    }

}
</script>
<style lang="scss" scoped>
.login{
    min-height: 100vh;
    display: flex;
    background: #fff;
    .login1{
        background: url(../../assets/imgs/bguser.jpg) no-repeat center center;
        background-size: cover;
    }
    .login2{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .login2a{
        width: 366px;
    }
    .logintxt{
        padding: 17px;
        line-height: 20px;
        color: #989898;
        font-size: 12px;
        text-align: center;
        border-top: 1px dashed #CCCCCC;
    }
}

</style>